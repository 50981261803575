import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  public isLoading = false;
  public errors: string;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {

  }

  async signIn() {
    this.isLoading = true;
    const token: Observable<any> = await this.authService.signIn();

    token.subscribe(tok => {
      if (tok.token) {
        this.router.navigate(['/']);
        this.isLoading = false;
      } else {
        this.errors = tok.errors.replace(new RegExp(/"/, 'g'), '').split('|')[1];
        this.isLoading = false;
      }
    });
  }

}
