import {Component, OnInit} from '@angular/core';
import {ConnectService} from "../services/connect.service";
import {filter} from "rxjs/operators";
import {AuthService} from "../services/auth.service";
import {NavigationEnd, Router} from "@angular/router";
import {Theme, ThemeService} from "../services/theme.service";
import {OverlayContainer} from "@angular/cdk/overlay";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  // constructor(private oAuthService: ConnectService){}
  //
  // connect(){
  //   this.oAuthService.getCode().subscribe((dialog: Dialog) => {
  //     const prompt = window.open('', 'prompt', "menubar=no, status=no, scrollbars=no, menubar=no, width=500, height=500");
  //     prompt.document.write(dialog.response.toString());
  //   });
  // }

  public theme: Theme;
  public themes: Theme[] = [];
  public showHeader: string;
  public isLogged:boolean;
  constructor(
    private _themeService: ThemeService,
    private _overlayContainer: OverlayContainer,
    public _route: Router,
    public authService: AuthService
  ) {
    _route.events.pipe(
      filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.showHeader = e.urlAfterRedirects;
        this.isLogged = this.authService.isLogged;
      });

    this.theme = this._themeService.getDefaultTheme();
    this.overlayContainerTheming(this.theme.value);
  }

  public ngOnInit() {
    this.themes = this._themeService.getThemes();
    this._themeService.getTheme().subscribe((next) => {
      this.theme = next;
    });
  }

  public toggleTheme(value: string) {
    this.overlayContainerTheming(value);
    this._themeService.setTheme(value);
  }

  private overlayContainerTheming(themeClassName: string) {
    const cl = this._overlayContainer.getContainerElement().classList;

    if (cl.length > 1) {
      this._overlayContainer.getContainerElement().classList.remove(cl.item(cl.length - 1));
    }

    this._overlayContainer.getContainerElement().classList.add(themeClassName);
  }

}
