import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public path: any;

  constructor(private authService: AuthService, private router: Router) {
  }

  private createPath(url){
    const pattern = new RegExp('mass-edit');
    const advPattern = new RegExp('[0-9]+');

    return pattern.test(url) ? {url: url, id_advertiser: +url.match(advPattern)[0]} : undefined;
  }

  ngOnInit() {
    this.path = this.createPath(this.authService.redirectUrl);

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.path = this.createPath(e.url);
      }
    })
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }


}
