import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {catchError, tap} from "rxjs/operators";
import {MsalService} from "@azure/msal-angular";
import {of, throwError} from "rxjs";
import {OAuthSettings} from "./oauth";

const httpOptions = { headers: new HttpHeaders({
    'Content-Type':'application/json'
  })};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public redirectUrl:string;
  public isLogged:boolean = undefined;
  private errors: string;
  public user: any;
  public token:string;

  constructor(private msalService: MsalService, private http: HttpClient) {
  
  }

  async signIn(): Promise<any> {
    let result = await this.msalService.loginPopup(OAuthSettings.scopes)
      .catch((reason) => {
        this.errors = JSON.stringify(reason, null, 2);
      });


    if (result) {

      this.user = this.msalService.getUser();

      let access_token = await this.getAccesstoken();

      return this.http.post('/api/auth/login', {access_token: access_token, email: this.user.displayableId}, httpOptions).pipe(
        catchError(this.handleError),
        tap((value: any) => {
          this.token = value.token;
          localStorage.setItem('access_token', value.token);
          this.isLogged = true;
        }));
    }

    return of({ errors: this.errors });
  }

  public IsAuthenticated() {
    this.isLogged = false;
    let httpOptions = { headers: new HttpHeaders({
        'Authorization':'Bearer ' + localStorage.getItem('access_token')
      })};

    return this.http.get('/api/auth/check_token', httpOptions).pipe(
      catchError(this.handleError),
      tap((value: any) => {
        this.token =  localStorage.getItem('access_token');
        this.user = value.user;
        this.isLogged = true;
      })
    );

  }

  public logout() {
    localStorage.clear();
    this.isLogged = false;
    this.user = undefined;
    return of({data: "ok"});

  }

  async getAccesstoken(): Promise<string> {
    let result = await this.msalService.acquireTokenSilent(OAuthSettings.scopes)
      .catch((reason) => {
        this.errors = JSON.stringify(reason, null, 2);
      });

    return result;
  }

  protected handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      this.isLogged = false;
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  };

}
