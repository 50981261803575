import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import { AppComponent } from './components/app.component';
import { HeaderComponent } from "./components/header/header.component";
import {AngularMaterialModule} from "./modules/angular-material/angular-material.module";
import {MsalModule} from "@azure/msal-angular";
import {JwtInterceptor} from "./services/jwt.interceptor";
import {LoginModule} from "./modules/login/login.module";
import {OAuthSettings} from "./services/oauth";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoginModule,
    MsalModule.forRoot({clientID: OAuthSettings.appId, redirectUri: OAuthSettings.redirectUri})
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
