import {Injectable} from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  Route,
  UrlSegment
} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/internal/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanLoad {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLogin(state.url);
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkLogin(location.pathname);
  }

  checkLogin(url: string): Observable<boolean> | boolean {
    if (url == '/' && this.authService.isLogged === false)
      return true;
    this.authService.redirectUrl = url;
    if (this.authService.isLogged) { return true; }
    return this.authService.IsAuthenticated().pipe(
      catchError((e) => {
        this.router.navigate(['/login']);
        return of(false);}),
      map((d) =>{
        if (d)
        {
          if (url == '/')
            this.router.navigate(['/'])
          return true;
        }
      }));
  }

}
